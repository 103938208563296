.app {
  position: relative;
  overflow-x: hidden;
}

.main {
  position: relative;
  z-index: 1;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* ------------------------------ */
/* header text */
/* ------------------------------ */
h1 {
  color: var(--text-color);
  font-size: 2.5rem;
  font-weight: bold;
}

h2 {
  color: var(--white);
  font-family: var(--font-family-en-title);
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 1.5rem;
  color: var(--main-color);
  text-align: left;
  margin-bottom: 20px;
}

/* ------------------------------ */
/* p */
/* ------------------------------ */
p,th,td,ul,li,a,span {
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
}

/* ------------------------------ */
/* link */
/* ------------------------------ */
a {
  text-decoration: none;
  color: var(--main-color);
}

/* ------------------------------ */
/* ボタン */
/* ------------------------------ */
button {
  align-items: center;
  background-color: transparent;
  border: 2px solid var(--main-color);
  border-radius: 30px;
  display: inline-flex;
  color: var(--main-color);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  justify-content: center;
  padding: 10px 20px;
  transition: all 0.3s ease;
} 

button:hover {
  background-color: var(--main-color);
  color: var(--white);
}

button:disabled {
  color: var(--white);
  border: none;
  background-color: var(--disabled-color);
  cursor: not-allowed;
}

/* ------------------------------ */
/* フォーム */
/* ------------------------------ */
label {
  margin-bottom: 5px;
  font-weight: bold;
}

input, textarea, select {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

input:focus, textarea:focus, select:focus,
input:focus-visible, textarea:focus-visible, select:focus-visible {
  border-color: var(--main-color);
  outline: none;
}

textarea{
  resize: none;
  height: 100px;
}

/* ------------------------------ */
/* ビュー */
/* ------------------------------ */
.view {
  max-width: 1200px;
  margin: 0 auto;
  padding: 180px 20px;
}

.subPageView {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 100px;
}

/* ------------------------------ */
/* セクション */
/* ------------------------------ */
.section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
  position: relative;
  width: 100%;
}

/* ------------------------------ */
/* スローガン */
/* ------------------------------ */
.slogan {
  text-align: left;
  font-size: 2rem;
  font-family: var(--font-family-ja);
  box-sizing: border-box;
}

/* ------------------------------ */
/* サブタイトル */
/* ------------------------------ */
.subtitle {
  color: var(--sub-color);
  font-size: 0.8rem;
  margin-top: 10px;
  margin-bottom: 50px;
}

/* ------------------------------ */
/* ディスクリプション */
/* ------------------------------ */
.description {
  line-height: 2;
  max-width: 70%;
  margin-bottom: 120px;
}

.subPageDescription {
  line-height: 2;
  max-width: 70%;
  margin-top: 30px;
}

/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  transform: translateY(0);
  display: flex;
  align-items: center;
}

.verticalText::before {
  content: '';
  display: block;
  width: 12px;
  height: 5px;
  background-color: var(--text-color);
  margin-right: 3px;
  margin-bottom: 10px;
}

.verticalText span {
  color: var(--text-color);
}

/* ------------------------------ */
/* * レスポンシブ */
/* ------------------------------ */
@media (max-width: 1250px) {
  .view {
    padding: 180px 60px;
  }
  .subPageView {
    padding: 120px 60px;
  }
}

@media (max-width: 780px) {
  .view {
    padding: 180px 30px;
  }
  .subPageView {
    padding: 120px 30px;
  }
  /* header text */
  h1, h2{
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  /* other */
  p,th,td,ul,li,a,span,button {
    font-size: 0.9rem;
  } 
}

@media (max-width: 560px) {
  /* header text */
  h1,h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  /* other */
  p,th,td,ul,li,a,span,button {
    font-size: 0.9rem;
  } 
}

@media (max-width: 480px) {
  /* header text */
  h1,h2,.slogan {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1rem;
  }
  /* other */
  p,th,td,ul,li,a,span,button {
    font-size: 0.75rem;
  } 
  .description {
    max-width: 80%;
  }
}
