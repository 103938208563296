:global(#privacy h3) {
  color: var(--text-color);
}

:global(#privacy ul) {
  margin-top: 10px;
  margin-bottom: 10px;
}

:global(#privacy li) {
  position: relative;
  padding-left: 15px;
  font-size: 0.9rem;
}

:global(#privacy li::before) {
  content: '-';
  position: absolute;
  left: 0;
}