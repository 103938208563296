/* ------------------------------ */
/* イメージ */
/* ------------------------------ */
.imageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.image {
  width: 60%;
  height: 60%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 5px 10px var(--shadow-color);
  box-sizing: border-box;
}

/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  left: 0;
  top: 0;
}

@media (max-width: 480px) {
  .imageWrapper {
    overflow: visible;
  }
  .image {
    width: 100%;
    height: 100%;
    margin-right: -50px;
  }
}