.parallaxContainer {
  height: 50vh;
  overflow: hidden;
  position: relative;
}

.parallaxImage {
  background-image: url('../../../public/images/image_01.jpg');
  background-size: cover;
  background-position: center;
  height: 150%;
  width: 100%;
  position: absolute;
  top: -250px;
  left: 0;
}