/* ------------------------------ */
/* サブタイトル */
/* ------------------------------ */
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 80px;
}

.card {
  background-color: var(--white);
  padding: 40px 40px 40px 60px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
  box-sizing: border-box;
  transition: all 0.3s ease;
  width: 70%;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15px;
  background: linear-gradient(180deg, var(--main-color), var(--gradient-blue));
}

.iconWrapper {
  text-align: left;
  margin-bottom: 20px;
}

.icon {
  font-size: 3rem;
  color: var(--main-color);
}

/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  right: 0;
  top: 0;
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 480px) {
  .grid {
    margin-top: 0px;
  }
  .card {
    padding: 40px 30px 40px 50px;
    width: 100%;
  }
  .card::before {
    width: 10px;
  }
}