.serviceGrid {
  display: grid;
  grid-template-columns: repeat(1, minmax(300px, 1fr));
  gap: 30px;
  width: 80%;
}

.serviceCard {
  background-color: var(--white);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px var(--shadow-color);
  transition: transform 0.3s ease;
}

.serviceTitle {
  color: var(--main-color);
  margin-bottom: 10px;
}

.serviceDescription {
  color: var(--text-color);
  margin-bottom: 20px;
}

.serviceDetails {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.detailItem {
  display: flex;
  align-items: center;
  background-color: var(--purple-background-color);
  padding: 10px 10px 10px 30px;
  border-radius: 30px;
}

.detailIcon {
  font-size: 1.2rem;
  color: var(--main-color);
  margin-right: 10px;
}

.detailName {
  font-size: 0.9rem;
  color: var(--text-color);
}

.learnMore {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--main-color);
  color: var(--white);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  right: 0;
  top: 0;
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 780px) {
  .serviceDetails {
    grid-template-columns: repeat(1, 1fr);
  }
  .detailItem {
    padding: 8px 8px 8px 24px;
  }
  .detailName {
    font-size: 0.8rem;
  }
}