.serviceDetail {
  max-width: 800px;
  margin: 0 auto;
  padding: 120px 20px;
}

.mainTitle {
  font-family: var(--font-family-en);
  color: var(--main-color);
  font-size: 3rem;
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.mainTitle::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  width: 60px;
  height: 3px;
  background-color: var(--main-color);
  transform: translateX(-50%);
}

.subtitle {
  text-align: center;
  font-size: 1.2rem;
  margin-top: -40px;
  margin-bottom: 60px;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  font-size: 1.8rem;
  color: var(--main-color);
  margin-bottom: 20px;
}

.section p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.section ul {
  padding-left: 20px;
}

.section li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
}