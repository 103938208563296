.philosophyGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 80%;
}

.philosophyItem {
  background-color: var(--white);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.philosophyItem h4 {
  font-size: 1.5rem;
  color: var(--main-color);
  margin-bottom: 10px;
}

.infoTable, .timelineTable {
  border-collapse: collapse;
  width: 80%;
}

.infoTable tr, .timelineTable tr {
  border-bottom: 1px solid var(--border-color);
  transition: all 0.3s ease;
}

.infoTable tr:last-child, .timelineTable tr:last-child {
  border-bottom: none;
}

.infoTable th, .timelineTable th {
  padding: 20px 15px 20px 0px;
  text-align: left;
  font-weight: bold;
  width: 250px;
  align-content: baseline;
}

.infoTable td, .timelineTable td {
  padding: 20px 15px;
}

.infoTable ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.infoTable li {
  margin-bottom: 5px;
}

/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  right: 0;
  top: 0;
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 1000px) {
  .philosophyGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 780px) {
  .infoTable th, .timelineTable th {
    padding: 10px 5px 10px 0px;
    width: 150px;
  }
  .infoTable td, .timelineTable td {
    padding: 10px 5px;
  }
}

@media (max-width: 480px) {
  .philosophyGrid {    
    width: 90%;
  }
  .philosophyItem h4 {
    font-size: 1.2rem;
  }
  .infoTable, .timelineTable {
    width: 90%;
  }
  .infoTable th, .timelineTable th {
    width: 90px;
  }
}