/* ------------------------------ */
/* サブタイトル */
/* ------------------------------ */
.newsList {
  list-style-type: none;
  padding: 0;
  width: 80%;
}

.newsItem {
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.newsDate {
  font-size: 0.9rem;
  color: var(--sub-color);
}

.newsTitle {
  margin: 10px 0;
  font-size: 1.2rem;
}

.newsContent {
  font-size: 1rem;
  line-height: 1.5;
}

/* ------------------------------ */
/* もっと見るボタン */
/* ------------------------------ */
.moreButtonWrapper {
  text-align: center;
  margin-top: 40px;
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--main-color);
  font-size: 1rem;
}

.moreButton svg {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.moreButton:hover svg {
  transform: translateY(3px);
}

/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  right: 0;
  top: 0;
}

/* ------------------------------ */
/* カルーセル */
/* ------------------------------ */
.carouselWrapper {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
  background-color: var(--white);
}

.carouselContainer {
  display: flex;
  transition: transform 0.5s ease;
  width: 600%; /* ニュースアイテムの数 * 100% */
}

.carouselItem {
  flex: 0 0 16.666%; /* 100% / ニュースアイテムの数 */
  padding: 40px;
  box-sizing: border-box;
}

.dotContainer {
  display: flex;
  justify-content: center;
  margin: 40px 5px 5px 5px;
  align-self: center;
}

.dot {
  padding: 0;
  border-radius: 5px;
  width: 70px;
  height: 7px;
  background-color: var(--disabled-color);
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.activeDot {
  background-color: var(--main-color);
}

.newsGrid {
  display: grid;
  gap: 30px;
  width: 80%;
  transition: all 0.3s ease;
}

.newsCard {
  background-color: var(--white);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px var(--shadow-color);
  transition: all 0.3s ease;
}

.newsCard:hover {
  transform: translateY(-5px);
}

.imageWrapper {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsContent {
  padding: 20px;
}

.newsDate {
  font-size: 0.9rem;
  color: var(--sub-color);
  display: block;
  margin-bottom: 10px;
}

.newsTitle {
  font-size: 1rem;
  margin: 0;
  line-height: 1.4;
}

@media (max-width: 780px) {
  .newsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .newsGrid {
    gap:10px;
    grid-template-columns: 1fr;
    width: 90%;
  }
  .newsDate {
    font-size: 0.7rem;
  }
  .newsTitle {
    font-size: 0.8rem;
  }
}