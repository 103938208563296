.positionsGrid,
.applyGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  gap: 30px;
  width: 80%;
}

.applyGrid {
  grid-template-columns: repeat(1, minmax(300px, 1fr));
}

.positionCard,
.applyCard {
  background-color: var(--white);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.positionCard h4,
.applyCard h4 {
  font-size: 1rem;
  color: var(--main-color);
  margin-bottom: 10px;
}

.positionCard p,
.applyCard p {
  height: 50px;
}

.positionCard h5,
.applyCard h5 {
  margin: 30px 0 10px;
}

.positionCard ul,
.applyCard ul {
  margin-bottom: 20px;
}

.positionCard ul li,
.applyCard ul li {
  margin-bottom: 5px;
}

.positionCard ul li::before,
.applyCard ul li::before {
  content: "- ";
}

/* ------------------------------ */
/* 外国人エンジニア・デザイナー */
/* ------------------------------ */
.foreignerCard {
  background-color: var(--white);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px var(--shadow-color);
  margin-bottom: 30px;
}

.foreignerCard h4 {
  color: var(--main-color);
  margin-bottom: 10px;
}

.requirementColumns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 20px;
}

.requirementColumns h5 {
  margin: 0 0 10px;
}

.requirementColumns ul {
  margin: 0;
  padding-left: 20px;
}

.requirementColumns li {
  margin-bottom: 5px;
}
.foreignerRecruitment {
  margin-top: 120px;
  width: 80%;
}

.foreignerDescription {
  margin-bottom: 30px;
  line-height: 1.8;
}
/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  right: 0;
  top: 0;
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 1200px) {
  .positionsGrid {
    grid-template-columns: 1fr;
    width: 100%;
  }
  
  .positionCard {
    width: 80%;
    box-sizing: border-box;
  }
}

@media (max-width: 780px) {
  .requirementColumns {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}