:root {
  --main-color: #5b48f2;
  --sub-color: #a0a0a0;
  --background-color: #f8f9fa;
  --nav-background-color: rgba(248, 249, 250, 0.9);
  --option-background-color: #d4d4d4;
  --purple-background-color: #e8ecff;

  --border-color: #e0e0e0;
  --text-color: #333333;
  
  --white: #ffffff;
  --black: #000000;
  
  --overlay-color: rgba(0, 0, 0, 0.5);
  --shadow-color: rgba(0, 0, 0, 0.1);
  --disabled-color: #ccc;
  --gradient-blue: #4a90e2;
  
  --font-family-ja: 'Noto Sans JP', sans-serif;
  --font-family-en: 'Barlow Condensed', sans-serif;
  --font-family-en-scroll: 'Chillax', sans-serif;
  --font-family-en-title: 'Dancing Script', sans-serif;
  --font-family-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

* {
  font-family: var(--font-family-ja);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-x: hidden;
}

code {
  font-family: var(--font-family-code);
}

h1, h2, h3, h4, h5, h6, p, span, ul, li {
  margin: 0;
  padding: 0;
}

ul,li {
  list-style-type: none;
}