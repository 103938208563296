.footer {
  background-color: var(--main-color);
  color: var(--white);
  padding: 60px 0 20px;
}

.footer .content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer .logoSection {
  margin-bottom: 40px;
  text-align: center;
}

.footer .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--white);
}

.footer .logo svg {
  margin-bottom: 10px;
}

.footer .logo span {
  font-family: var(--font-family-en);
  font-size: 1.2rem;
  font-weight: bold;
}

.footer .linksSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin: 40px 0 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.footer .linkColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: 100px;
}

.footer .linkColumn a {
  color: var(--white);
  margin-bottom: 10px;
  font-size: 0.8rem;
  transition: color 0.3s ease;
}

.footer .copyright {
  text-align: center;
  font-size: 0.8rem;
  color: var(--white);
  padding-top: 20px;
  width: 100%;
}

.footer .copyright p {
  color: var(--white);
  font-size: 0.8rem;
  text-align: center;
}

.footer .divider {
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
  margin: 40px 0;
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 1250px) {
  .footer .divider {
    margin: 40px 60px;
  }
  .footer .content {
    margin: 0 60px;
  }
  .footer .linksSection {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .footer .linkColumn {
    margin-left: 40px;
  }
}

@media (max-width: 780px) {
  .footer .divider {
    margin: 30px;
  }
  .footer .content {
    margin: 0 30px;
  }
  .footer .linksSection {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer .linkColumn {
    margin-left: 20px;
    gap: 5px;
  }
  .footer .linkColumn a {
    margin-bottom: 5px;
  }
  .footer .copyright p {
    font-size: 0.7rem;
  }
}