.fadeIn {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.visible .fadeIn {
  opacity: 1;
  transform: translateY(0);
}

.visible .fadeIn:nth-child(1) { transition-delay: 0.1s; }
.visible .fadeIn:nth-child(2) { transition-delay: 0.2s; }
.visible .fadeIn:nth-child(3) { transition-delay: 0.3s; }
.visible .fadeIn:nth-child(4) { transition-delay: 0.4s; }

.fadeInLeft {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fadeInRight {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fadeInTop {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.visible .fadeInLeft,
.visible .fadeInRight,
.visible .fadeInTop,
.visible .fadeIn {
  opacity: 1;
  transform: translate(0);
}

.visible .fadeInLeft:nth-child(1) { transition-delay: 0.1s; }
.visible .fadeInLeft:nth-child(2) { transition-delay: 0.2s; }
.visible .fadeIn:nth-child(3) { transition-delay: 0.3s; }
.visible .fadeInTop { transition-delay: 0.4s; }
.visible .fadeInRight { transition-delay: 0.5s; }
