.scrollTextContainer {
  overflow: hidden;
  white-space: nowrap;
  background-color: var(--main-color);
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.scrollText {
  display: inline-block;
  font-family: var(--font-family-en-scroll);
  font-size: 5rem;
  color: var(--white);
  will-change: transform;
  animation: scrollText 30s linear infinite;
  text-shadow: 8px 8px var(--shadow-color);
}

@keyframes scrollText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 1250px) {
  .scrollText {
    font-size: 4rem;
  }
}

@media (max-width: 780px) {
  .scrollText {
    font-size: 3.5rem;
  }
}

@media (max-width: 560px) {
  .scrollText {
    font-size: 3rem;
  }
}