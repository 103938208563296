.homeContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  position: relative;
}

/* ------------------------------ */
/* Break the mold */
/* ------------------------------ */
.homeContainer .mainTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeContainer .mainTitle .animatedText{
  color: var(--main-color);
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: center;
}
/* ------------------------------ */
/* 革新的かつ、オリジナルなDX化を。 */
/* ------------------------------ */
.homeContainer .subTitle .animatedText{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeContainer .subTitle .animatedText{
  font-size: 1.5rem;
}

/* ------------------------------ */
/* スクロールインジケータ */
/* ------------------------------ */
.homeContainer .scrollIndicatorWrapper {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.5s ease-out;
  opacity: 0;
}

.homeContainer .scrollText {
  color: var(--main-color);
  font-size: 1rem;
  margin-bottom: 5px;
}

.homeContainer .scrollIndicator {
  width: 30px;
  height: 50px;
  position: relative;
}

.homeContainer .scrollIndicator::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--main-color);
  border-radius: 50%;
  transform: translateX(-50%);
  animation: scrollAnimation 2s infinite;
}

/* ------------------------------ */
/* アニメーション */
/* ------------------------------ */
.homeContainer .animatedText {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease forwards;
}

.homeContainer .mainTitle .animatedText {
  animation-delay: 0.5s;
  font-size: 4rem;
  font-family: var(--font-family-en-title);
}

.homeContainer .subTitle .animatedText {
  animation-delay: 1s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scrollAnimation {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(50px);
  }
}

.homeContainer .fadeIn {
  opacity: 1;
}

.homeContainer .fadeOut {
  opacity: 0;
}

/* ------------------------------ */
/* * レスポンシブ */
/* ------------------------------ */
@media (max-width: 780px) {
  .homeContainer .mainTitle .animatedText{
    font-size: 3.5rem;
  }
  .homeContainer .subTitle .animatedText{
    font-size: 1.3rem;
  }
}
@media (max-width: 560px) {
  .homeContainer .mainTitle .animatedText{
    font-size: 3rem;
  }
  .homeContainer .subTitle .animatedText{
    font-size: 1rem;
  }
}
