/* ------------------------------ */
/* サブタイトル */
/* ------------------------------ */
.projectsWrapperContainer {
  max-width: 100%;
  margin: 0 auto;
}

.projectsWrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
  width: 200%;
}

.projectCard {
  flex: 0 0 300px;
  overflow: hidden;
}

.imageWrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textWrapper {
  padding: 10px 0;
}

.categoryList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

.categoryItem {
  font-size: 0.8rem;
  background-color: var(--main-color);
  color: var(--white);
  padding: 2px 8px;
  border-radius: 10px;
  width: fit-content;
}

.verticalText {
  right: 0;
  top: 0;
}

/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  right: 0;
  top: 0;
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 1200px) {
  .projectsWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1000px) {
  .projectsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 780px) {
}