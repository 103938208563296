.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--nav-background-color);
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  height: 70px;
}

/* ------------------------------ */
/* ロゴ */
/* ------------------------------ */
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.logoText {
  color: var(--main-color);
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: -1px;
}

/* ------------------------------ */
/* メニュー */
/* ------------------------------ */
.menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin-left: 2rem;
}

.menu a {
  color: var(--main-color);
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  white-space: nowrap;
  font-family: var(--font-family-ja);
  font-weight: 600;
  text-transform: lowercase;
}

.menu a:hover {
  color: var(--sub-color);
}

/* ------------------------------ */
/* ハンバーガーメニュー*/
/* ------------------------------ */

.hamburgerButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: var(--main-color);
  border: none;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  cursor: pointer;
}

.hamburgerButton span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: var(--white);
  margin: 2px 0;
  transition: all 0.3s ease;
}

.hamburgerButton.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5.5px);
}

.hamburgerButton.open span:nth-child(2) {
  opacity: 0;
}

.hamburgerButton.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5.5px);
}

.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background-color: var(--main-color);
}

.mobileMenu.open {
  opacity: 1;
  visibility: visible;
}

.menuBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--main-color);
  transform: scale(0);
  transition: transform 0.5s ease;
  border-radius: 50%;
}

.mobileMenu.open .menuBackground {
  transform: scale(1);
}

.mobileMenu ul {
  list-style-type: none;
  padding: 0;
  position: relative;
  z-index: 1;
}

.mobileMenu li {
  margin: 20px 0;
  text-align: center;
  opacity: 0;
  transform: translateY(20px) rotateX(90deg);
  transition: opacity 0.3s ease, transform 0.5s ease;
}

.mobileMenu.open li {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}

.mobileMenu.open li:nth-child(1) { transition-delay: 0.1s; }
.mobileMenu.open li:nth-child(2) { transition-delay: 0.2s; }
.mobileMenu.open li:nth-child(3) { transition-delay: 0.3s; }
.mobileMenu.open li:nth-child(4) { transition-delay: 0.4s; }
.mobileMenu.open li:nth-child(5) { transition-delay: 0.5s; }

.mobileMenu a {
  color: var(--white);
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  position: relative;
  transition: color 0.3s ease;
  display: inline-block;
  padding: 5px 0;
}

.mobileMenu.animating li {
  transition-delay: 0s;
}

@keyframes backgroundAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

/* ------------------------------ */
/* アニメーション */
/* ------------------------------ */
.visible {
  transform: translateY(0);
}

.hidden {
  transform: translateY(-100%);
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 780px){
  .logoText {
    display: none;
  }
  .menu {
    display: none;
  }
}

