.release {
  width: 100%;
  padding: 180px 60px;
  box-sizing: border-box;
  overflow: hidden;
}

.view {
  align-items: flex-start;
  padding: 0;
  gap: 0;
}

/* ------------------------------ */
/* サブタイトル */
/* ------------------------------ */
.descriptionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* ------------------------------ */
/* カード */
/* ------------------------------ */
.cardContainerWrapper {
  width: 80%;
  overflow: hidden;
  align-self: flex-end;
  border-radius: 10px;
  box-shadow: 0 5px 10px var(--shadow-color);
}

.cardContainer {
  display: flex;
  transition: transform 0.5s ease;
  width: 300%; /* カードの数 * 100% */
}

.card {
  flex: 0 0 33.333%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
}

/* ------------------------------ */
/* イメージ */
/* ------------------------------ */
.imageWrapper {
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9のアスペクト比 */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.image {
  position: absolute;
  top: 0;
  left: 1px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ------------------------------ */
/* ドット */
/* ------------------------------ */
.dotContainer {
  display: flex;
  justify-content: center;
  margin: 40px 5px 5px 5px;
  align-self: flex-end;
}

.dotContainer button {
  padding: 0;
  border-radius: 5px;
  width: 70px;
  height: 7px;
}

.dotContainer .dot {
  background-color: var(--disabled-color);
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dotContainer .activeDot {
  background-color: var(--main-color);
}

/* ------------------------------ */
/* バーティカルテキスト */
/* ------------------------------ */
.verticalText {
  left: 0;
  top: 0;
}

/* ------------------------------ */
/* アニメーション */
/* ------------------------------ */
.fadeIn {
  animation: fadeIn 0.8s ease-out forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.active {
  transform: scale(1.1);
  z-index: 2;
}

.inactive {
  transform: scale(0.9);
  opacity: 0.7;
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 780px) {
  .release {
    padding: 120px 30px;
  }
  /* ドット */
  .dotContainer {
    margin: 20px 5px 5px 5px;
  }
}

@media (max-width: 480px) {
  .cardContainerWrapper {
    margin-right: -35px;
    width: 100%;
  }
  .dotContainer {
    align-self: flex-start;
    margin: 20px 5px 5px 35px;
  }
}
