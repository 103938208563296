.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease;
  overflow: hidden;
  position: relative;
  padding: 0;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.logo {
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  transition: transform 0.3s ease;
  z-index: 2;
  margin: 0 auto;
  display: block;
}

.text {
  text-align: center;
  color: var(--white);
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 2;
  margin-top: 3rem;
}

.text p {
  color: var(--white);
  font-family: var(--font-family-en-title);
  font-size: 1.2rem;
  text-align: center;
}

/* ------------------------------ */
/* * レスポンシブ */
/* ------------------------------ */
@media (max-width: 768px) {
  .logo {
    max-width: 370px;
    max-height: 370px;
  }
  .text p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .logo {
    max-width: 280px;
    max-height: 280px;
  }
  .text p {
    font-size: 0.9rem;
  }
}