.section {
  margin-top: 0;
}

/* ------------------------------ */
/* フォーム */
/* ------------------------------ */
.formContainer {
  display: flex;
  justify-content: center;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
  box-sizing: border-box;
  padding: 100px 0;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.optional {
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 0.7rem;
  font-weight: 100;
  margin-left: 5px;
  background-color: var(--option-background-color);
}

/* ------------------------------ */
/* reCAPTCHA */
/* ------------------------------ */
.recaptchaContainer {
  display: flex;
  justify-content: flex-start;
}

.recaptcha {
  margin-top: 20px;
}

.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.grecaptcha-badge.visible {
  visibility: visible;
  opacity: 1;
}

/* ------------------------------ */
/* お問い合わせ完了 */
/* ------------------------------ */
.homeButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

/* ------------------------------ */
/* レスポンシブ */
/* ------------------------------ */
@media (max-width: 1200px) {
  .formContainer {
    padding: 0;
  }

  .form {
    width: 100%;
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .recaptchaContainer {
    justify-content: center;
  }
}

